import { Language } from './language';

const it = {
    code: Language.it,

	// HOME PAGE

	t_description: 'Batterista ragazza, {age} anni',
	t_close: 'Vicino',

	b_home: 'Inizio',
	b_about: 'Su di me',
	b_press: 'Premere',
	b_shows: 'Spettacoli',
	b_soultone: 'Soultone',
	b_collision: 'Collision',
	b_language: 'Italiano',
	b_share: 'Condividere',
	b_scroll_to_top: 'Vai all\'inizio',
	b_click: 'Clic',
	b_tap: 'Premere',

	t_english: 'Inglese',
	t_spanish: 'Spagnolo',
	t_portuguese: 'Portoghese',
	t_italian: 'Italiano',
	t_french: 'Francese',
	t_german: 'Tedesco',
	t_chinese: 'Cinese',

	// SHOWS PAGE

	ClassicMetal_title: 'Metallo classico',
	ClassicMetal_description: 'School of Rock Seattle',

	StAlsGrandDay2024_title: 'Presentazione della Festa dei Nonni 2024',
	StAlsGrandDay2024_description: 'Scuola Sant\'Alfonso di Seattle',

	BlackViolinConcert_title: 'Concerto di violino nero con The World Your Orchestra',
	BlackViolinConcert_description: 'Il Teatro Paramount',

	MexicanIndependenceDay2024_title: 'Celebrazione dell\'Indipendenza del Messico con l\'Orchestra Civica Ballard',
	MexicanIndependenceDay2024_description: 'Municipio di Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival dei frutti di mare Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival dei frutti di mare Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',
	
	ModernMetal_title: 'Metallo moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Anteprima stagione inverno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Metallo per capelli',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Talent Show 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Anteprima di stagione primavera 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'I Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Spettacolo di colonne sonore',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia anni \'90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Talent Show 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	b_watch_here: 'Vedi qui',

	fox_13_news_title: 'Rapporto sulle notizie di Fox 13 Seattle',
	fox_13_news_description: 'Sezione del vero nord-ovest con',

	the_daly_migs_show_interview_title: 'Intervista con The Daly Migs Show',
	the_daly_migs_show_interview_description: 'Intervista sull\'essere invitato a salire sul palco con i Godsmack',

	alizee_defan_07_02_2024_title: 'Intervista con "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Intervista esclusiva',

	jaime_mendez_news_06_26_2024_title: 'Intervista a "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Notizie locali di Seattle in spagnolo',

	bien_informado_03_22_2023_title: 'Articolo di "Bien Informado"',
	bien_informado_03_22_2023_description: 'Articolo del sito di notizie argentino su Victoria',

	wave_potter_reaction_01_30_2023_title: 'La reazione di Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reagisce alla copertina di Victoria Lux Æterna dei Metallica',
	
	// ABOUT PAGE

	b_back: 'Torna indietro',

	t_title: 'Victoria Acosta',
	t_pronouns: '(lei)',

	b_read_more: 'Per saperne di più',
	b_read_less: 'Leggi di meno',

	t_about: `
Incontra Victoria Acosta, la batterista elettrizzante di {age} anni, la cui energia vibrante e passione instancabile per la musica stanno conquistando il mondo! Nata a El Paso, Texas, con radici a Ciudad Juárez, Messico, ha trascorso la sua infanzia nelle vivaci strade di Guadalajara. Oggi vive a Seattle, Washington, e continua a ispirare il pubblico con il suo talento straordinario e il suo impegno per la sua arte.

La passione di Victoria per la batteria è nata quando aveva solo quattro anni. Mentre la maggior parte dei bambini giocava con i giocattoli, questa straordinaria giovane talento era già impegnata a battere dei ritmi e dichiarava alla sua famiglia che la batteria era il suo strumento preferito.

Il 13 settembre 2018, il suo sogno si è avverato quando ha aperto il suo primo set di batteria, e da quel momento non c'è stato più nulla che potesse fermarla! Si è iscritta alla rinomata Musicalerias Academy di Guadalajara, immergendosi nei corsi formali e padroneggiando l'arte del ritmo come una vera star del rock.

La sua avventura ha preso una nuova piega quando la sua famiglia si è trasferita a Seattle il 10 ottobre 2019. Inarrestabile, ha continuato i suoi studi online, dimostrando che la distanza non è un ostacolo per una musicista appassionata. Il suo grande colpo di fortuna è arrivato il 29 maggio 2022, quando ha preso una lezione di prova alla leggendaria School of Rock. Mentre la maggior parte dei suoi compagni iniziava nel programma base Rock 101, il talento di Victoria è emerso brillantemente, portandola a un posto ambito nel programma avanzato Performers Program. Da allora, ha conquistato il palco in {shows} concerti dal vivo con la sua energia contagiosa e la sua magnetica presenza sul palco.

Ma questo è solo l'inizio! Victoria non è solo un'interprete, è anche una sensazione sui social media, avendo registrato centinaia di video affascinanti che mostrano la sua straordinaria abilità alla batteria. Il 13 giugno 2024 ha fatto scalpore come batterista ospite per il compositore italiano acclamato Alberto Rigonni nel Song Symposium per l'album Nemesis Call, un segno del suo rapido ascesa nel panorama musicale!

In un emozionante traguardo, Victoria ha firmato un contratto di sponsorizzazione con Soultone Cymbals il 12 settembre 2024, consolidando il suo posto tra i migliori batteristi e portando il suo suono a un livello superiore.

Come se non bastasse, Victoria ha ampliato i suoi orizzonti musicali imparando il violino in quarta elementare. Il 13 maggio 2024 è entrata a far parte della World Youth Orchestra e ha avuto l'onore di esibirsi con il famoso duo Black Violin al Paramount Theatre, incantando un pubblico di 2.000 persone con il suo talento straordinario.

Ma il suo viaggio non si è fermato qui! Il 29 agosto 2024, è entrata a far parte della Ballard Civic Orchestra come percussionista, facendo il suo debutto al Town Hall di Seattle durante la celebrazione dell'Indipendenza del Messico davanti a quasi 1.000 fan entusiasti.

I riconoscimenti di Victoria sono altrettanto impressionanti delle sue performance. Ha vinto il Gold Prize e il premio per la Tecnica Fantastica al London Young Musician Contest 2022 ed è stata premiata con l'Annual Star Musician Award e l'Annual Outstanding Drummer Special Award ai London Young Musician of the Year 2023.

Ora ben radicata a Seattle, Victoria pratica ogni giorno, spingendo i limiti delle sue abilità alla batteria e al violino. Con lo sguardo rivolto a un futuro entusiasmante nel mondo della musica, questa giovane artista dinamica ha appena iniziato. Preparati a essere ispirato da Victoria Acosta, dove il ritmo incontra la passione e i sogni prendono vita!
`,

	// PRESS PAGE

	t_footer: 'Tutti i diritti riservati',

	// SOULTONE: 

	b_visit_artist_page: 'Visita la mia pagina artista',

};

export default it;
