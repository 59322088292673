import { Language } from './language';

const es = {
    code: Language.es,

	// HOME PAGE

	t_description: 'Niña baterista, {age} años de edad',
	t_close: 'Cerrar',

	b_home: 'Inicio',
	b_about: 'Acerca de mi',
	b_press: 'Prensa',
	b_shows: 'Shows',
	b_soultone: 'Soultone',
	b_collision: 'Collision',
	b_language: 'Español',
	b_share: 'Compartir',
	b_scroll_to_top: 'Ir al inicio',
	b_click: 'Click',
	b_tap: 'Presionar',

	t_english: 'Inglés',
	t_spanish: 'Español',
	t_portuguese: 'Portugués',
	t_italian: 'Italiano',
	t_french: 'Francés',
	t_german: 'Alemán',
	t_chinese: 'Chino',

	// SHOWS PAGE

	ClassicMetal_title: 'Metal Clásico',
	ClassicMetal_description: 'School of Rock Seattle',

	StAlsGrandDay2024_title: 'Presentación del dia de los abuelos 2024',
	StAlsGrandDay2024_description: 'Escuela de San Alfonso en Seattle',

	BlackViolinConcert_title: 'Concierto Black Violin con The World Your Orchestra',
	BlackViolinConcert_description: 'Teatro The Paramount',

	MexicanIndependenceDay2024_title: 'Celebracion de la Independencia de Mexico con La Orquesta Civica de Ballard',
	MexicanIndependenceDay2024_description: 'Ayuntamiento de Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival de comida del mar en Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival de comida del mar en Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Metal Moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Concierto de avance de invierno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Hair Metal',
	HairMetal_description: 'School of Rock Seattle',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Show de Talentos 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	SeasonPreviewSpring2023_title: 'Concierto de avance de primavera 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Los Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Show de Soundtracks',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia de los 90\'s',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Show de Talentos 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	b_watch_here: 'Ver aquí',

	fox_13_news_title: 'Reportaje de Fox 13 Seattle News',
	fox_13_news_description: 'Reportaje en la sección "True Northwest"',

	the_daly_migs_show_interview_title: 'Entrevista con The Daly Migs Show',
	the_daly_migs_show_interview_description: 'Entrevista acerca de ser invitada a subir al escenario con Godsmack',

	alizee_defan_07_02_2024_title: 'Entrevista con "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Entrevista exclusiva',

	jaime_mendez_news_06_26_2024_title: 'Entrevista con "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Noticiero local de Seattle en español',

	bien_informado_03_22_2023_title: 'Artículo de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Artículo de Sitio de Noticias Argentino acerca de Victoria',

	wave_potter_reaction_01_30_2023_title: 'Reacción de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reacciona al cover de Victoria Lux Æterna de Metallica',
	
	// ABOUT PAGE

	b_back: 'Regresar',

	t_title: 'Victoria Acosta',
	t_pronouns: '(ella)',

	b_read_more: 'Leer mas',
	b_read_less: 'Leer menos',

	t_about: `
Conoce a Victoria Acosta, la electrizante baterista de {age} años cuya vibrante energía y pasión imparable por la música están conquistando al mundo. Nacida en El Paso, Texas, y con raíces en Ciudad Juárez, México, pasó su infancia en las bulliciosas calles de Guadalajara. Ahora viviendo en Seattle, Washington, Victoria continúa inspirando al público con su extraordinario talento y dedicación a su arte.

La fascinación de Victoria por la batería se encendió a la temprana edad de cuatro años. Mientras la mayoría de los niños jugaban con juguetes, esta talentosa artista estaba ocupada marcando ritmos y declarando a la batería como su instrumento favorito a su familia.

El 13 de septiembre de 2018, su sueño se hizo realidad cuando recibió su primera batería, ¡y desde ese momento nada la detuvo! Inscribiéndose en la prestigiosa Academia Musicalerias en Guadalajara, se sumergió de lleno en lecciones formales, dominando el arte del ritmo como una verdadera estrella de rock.

La historia de Victoria alcanzó nuevas alturas cuando su familia se mudó a Seattle el 10 de octubre de 2019. Lejos de desanimarse por la transición, continuó su formación en línea, demostrando que la distancia no es un obstáculo para una músico apasionada. Su gran oportunidad llegó el 29 de mayo de 2022, cuando tomó una clase de prueba en la icónica School of Rock. Mientras la mayoría de sus compañeros comenzaban en el programa básico Rock 101, el talento de Victoria brilló, obteniendo un lugar codiciado en el avanzado Performers Program. Desde entonces, ha deslumbrado al público en {shows} shows en vivo con su energía contagiosa y magnética presencia en el escenario.

¡Y esto es solo el comienzo! Victoria no es solo una intérprete; es una sensación en las redes sociales, habiendo grabado cientos de videos cautivadores que muestran su destreza en la batería. El 13 de junio de 2024, dejó huella como baterista invitada del aclamado compositor italiano Alberto Rigonni en el Song Symposium para el álbum Nemesis Call, ¡un testimonio de su estatus en ascenso!

En un emocionante hito, Victoria firmó un acuerdo de patrocinio con Soultone Cymbals el 12 de septiembre de 2024, consolidando su lugar entre la élite de los bateristas y llevando su sonido al siguiente nivel.

Por si fuera poco, Victoria amplió sus horizontes musicales aprendiendo a tocar el violín en cuarto grado. Al unirse a la World Youth Orchestra el 13 de mayo de 2024, tuvo el honor de presentarse junto al renombrado dúo Black Violin en el Paramount Theatre, deslumbrando a una multitud de 2,000 personas con su extraordinario talento.

¡Pero su viaje no terminó ahí! El 29 de agosto de 2024, se unió a la Ballard Civic Orchestra como percusionista, debutando en el Town Hall Seattle durante la celebración del Día de la Independencia de México frente a casi 1,000 fans entusiastas.

Los logros de Victoria son tan impresionantes como sus presentaciones. Obtuvo el Premio de Oro y el reconocimiento a la Técnica Fantástica en el Concurso London Young Musician 2022 y fue galardonada con el Premio Anual de Músico Estrella y el Premio Especial de Baterista Sobresaliente en The London Young Musician of the Year 2023.

Ahora establecida en Seattle, Victoria practica diariamente, superando los límites de sus habilidades en la batería y el violín. Con la mirada puesta en un futuro emocionante en la música, esta joven y dinámica artista apenas está comenzando. ¡Prepárate para inspirarte con Victoria Acosta, donde el ritmo se encuentra con la pasión y los sueños se hacen realidad!
`,

	// PRESS PAGE

	t_footer: 'Todos los derechos reservados',

		// SOULTONE: 

		b_visit_artist_page: 'Visita mi página de artista'

};

export default es;