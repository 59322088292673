import LocalizedStrings from 'react-localization';

import { Language } from './lang/language';

import { numToWord as germanNumInWords } from 'num-words-de'; 

import es from './lang/es';
import en from './lang/en';
import pt from './lang/pt';
import it from './lang/it';
import fr from './lang/fr';
import de from './lang/de';
import zh from './lang/zh';

const writtenNumber = require('written-number');
const chineseNumInWords = require('number-to-chinese-words');

export default class Localization {
  private static instance: Localization;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static strings: any;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  public static getStrings() {
    return this.strings || (this.strings = new LocalizedStrings({
      en: en,
      es: es,
      pt: pt,
      it: it,
      fr: fr,
      de: de,
      zh: zh
    }));
  }

  public static getLanguage(): Language {
    const language = this.strings.getLanguage();
    return language;
  }

  public static setLanguage(lan?: Language) {
    let language = '';
    if(lan) {
      language = lan;
    }
    else if (typeof (localStorage as Storage) !== 'undefined') {
      language = localStorage.language ? localStorage.language : language;
    }
    else if (typeof (navigator as Navigator) !== 'undefined') {
      const navLang = navigator.language.split('-')[0];
      language =  navLang in Language ? navLang : language;
    } else {
      language = Language.en;
    }
    language = language in Language ? language : Language.en as string;
    this.getStrings().setLanguage(language);

    if (typeof (localStorage as Storage) !== 'undefined') {
      localStorage.setItem('language', language);
    }
  }
  
  public static translateNumber(num: number) {
    const locale: Language = Localization.getLanguage();
    if(locale === Language.zh) {
      return chineseNumInWords.toWords(num).toLocaleLowerCase(); 
    }
    if(locale === Language.de) {
      return germanNumInWords(num).toLocaleLowerCase();
    }
    return writtenNumber(num, {lang: locale}).toLocaleLowerCase();           
  }
}
