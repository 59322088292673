import React from 'react';
import { Button } from 'react-bootstrap';
import { AltNavBar } from '../components/NavBar';
import Localization from '../localization';

import '../styles/Endorsements.scss';

import config from '../config/web-site.json';

const strings = Localization.getStrings();

const { page, images } = config.endorsements.soultone;

type SoultoneProps = {
  scroller: (arg: string) => void;
}

const Soultone: React.FC<SoultoneProps> = (props) => {
  const { scroller } = props;

  return (
    <section id='soultone' data-testid='soultone-view'>
    <AltNavBar {...{ scroller, source: config.pages.soultone.title }} className='soultone-nav-bar'/>
      <div className='collage-header-soultone'>
        <img src={require('../assets/img/soultone_logo.png')} alt='Soultone logo' />
        <Button variant='outline-primary' href={page} target='_blank' className='artist-page-button'>{strings.b_visit_artist_page}</Button>
      </div>
      <section className='collage'>
        {images.map((image, index) => (
        <div key={index} className='collage-item'>
          <img src={require(`../assets/img/soultone/${image}`)} alt={`Collage item ${index + 1}`} />
        </div>
        ))}
      </section>
    </section>
  );
};

export default Soultone;