import { Language } from './language';

const de = {
    code: Language.de,

	// HOME PAGE
	
	t_description: '{age} Jahre altes Schlagzeugermädchen',
	t_close: 'Schließen',

	b_home: 'Startseite',
	b_about: 'Über mich',
	b_press: 'Drücken Sie',
	b_shows: 'Zeigt an',
	b_soultone: 'Soultone',
	b_collision: 'Collision',
	b_language: 'Deutsch',
	b_share: 'Aktie',
	b_scroll_to_top: 'Nach oben scrollen',
	b_click: 'Klicken',
	b_tap: 'Tippen',

	t_english: 'Englisch',
	t_spanish: 'Spanisch',
	t_portuguese: 'Portugiesisch',
	t_italian: 'Italienisch',
	t_french: 'Französisch',
	t_german: 'Deutsch',
	t_chinese: 'Chinesisch',

	// SHOWS PAGE

	ClassicMetal_title: 'Klassisches Metall',
	ClassicMetal_description: 'School of Rock Seattle',

	StAlsGrandDay2024_title: 'Aufführung am Großen Tag 2024',
	StAlsGrandDay2024_description: 'St. Alphonsus Parish School Seattle',

	BlackViolinConcert_title: 'Schwarzes Violinkonzert mit The World Your Orchestra',
	BlackViolinConcert_description: 'Das Paramount Theater',

	MexicanIndependenceDay2024_title: 'Feier zum mexikanischen Unabhängigkeitstag mit dem Ballard Civic Orchestra',
	MexicanIndependenceDay2024_description: 'Rathaus Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Ballard Seafood Fest 2024',
	BallardSeaFoodFest2024_description: 'Ballard Seafood Fest',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Moderner Metal',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Saisonvorschau Winter 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Haarmetall',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Talentshow 2023',
	TalentShow2023_description: 'Grundschule Seattle Salmon Bay',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Saisonvorschau Frühjahr 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'The Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Soundtrack Show',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgie der 90er Jahre',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Talentshow 2022',
	TalentShow2022_description: 'Grundschule Seattle Salmon Bay',
	
	// PRESS PAGE

	b_watch_here: 'Hier ansehen',

	fox_13_news_title: 'Fox 13 Seattle News mit',
	fox_13_news_description: 'Wahrer Nordwestabschnitt mit',

	the_daly_migs_show_interview_title: 'Interview mit der Daly Migs Show',
	the_daly_migs_show_interview_description: 'Interview über die Einladung zu Godsmack on Stage',

	alizee_defan_07_02_2024_title: '"Alizee Defan" interview',
	alizee_defan_07_02_2024_description: 'Eksklusivt interview',

	jaime_mendez_news_06_26_2024_title: '"Jaime Mendez Nieuws"-interview',
	jaime_mendez_news_06_26_2024_description: 'Lokaal Seattle-nieuws in het Spaans',

	bien_informado_03_22_2023_title: 'Artikel von "Bien Informado',
	bien_informado_03_22_2023_description: 'Artikel auf der argentinischen Nachrichtenseite über Victoria Drums',

	wave_potter_reaction_01_30_2023_title: 'Wave Potter Reaktion',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reagiert auf Victorias Cover von Lux Æterna von Metallica',

	// ABOUT PAGE

	b_back: 'Geh zurück',

	t_title: 'Victoria Acosta',
	t_pronouns: '(sie/ihr)',

	b_read_more: 'Mehr lesen',
	b_read_less: 'Weniger anzeigen',

	t_about: `
Lerne Victoria Acosta kennen, die elektrisierende {age}-jährige Schlagzeugerin, deren lebendige Energie und unermüdliche Leidenschaft für Musik die Welt im Sturm erobern! Geboren in El Paso, Texas, mit Wurzeln in Ciudad Juárez, Mexiko, verbrachte sie ihre Kindheit in den lebhaften Straßen von Guadalajara. Heute lebt sie in Seattle, Washington, und inspiriert weiterhin das Publikum mit ihrem außergewöhnlichen Talent und ihrer Hingabe an ihre Kunst.

Victorias Faszination für das Schlagzeug entfachte im zarten Alter von vier Jahren. Während die meisten Kinder mit Spielzeugen spielten, war diese bemerkenswerte Talente damit beschäftigt, Rhythmen zu klopfen und ihrer Familie das Schlagzeug als ihr Lieblingsinstrument zu erklären.

Am 13. September 2018 wurde ihr Traum wahr, als sie ihr erstes Schlagzeugset auspackte. Und von diesem Moment an gab es kein Zurück mehr! Sie schrieb sich an der renommierten Musicalerias Academy in Guadalajara ein und tauchte mit voller Energie in formelle Unterrichtseinheiten ein, wobei sie das Handwerk des Rhythmus wie ein echter Rockstar beherrschte.

Victorias Reise erreichte neue Höhen, als ihre Familie am 10. Oktober 2019 nach Seattle zog. Unbeirrt setzte sie ihre Ausbildung online fort und bewies, dass Entfernung kein Hindernis für eine leidenschaftliche Musikerin ist. Ihr großer Durchbruch kam am 29. Mai 2022, als sie eine Probestunde an der legendären School of Rock nahm. Während die meisten ihrer Mitschüler im Anfängerprogramm Rock 101 begannen, erstrahlte Victorias Talent, und sie erhielt einen begehrten Platz im fortgeschrittenen Performers Program. Seitdem hat sie das Publikum mit ihrer ansteckenden Energie und ihrer magnetischen Bühnenpräsenz in {shows} Live-Shows begeistert.

Aber das ist nur der Anfang! Victoria ist nicht nur eine Performerin, sondern auch ein Social-Media-Phänomen, da sie hunderte fesselnde Videos aufgenommen hat, die ihr Schlagzeug-Talent zeigen. Am 13. Juni 2024 machte sie Wellen als Gast-Schlagzeugerin für den renommierten italienischen Komponisten Alberto Rigonni im Song Symposium für das Album Nemesis Call – ein Beweis für ihren aufsteigenden Status!

In einem aufregenden Meilenstein unterzeichnete Victoria am 12. September 2024 einen Sponsorenvertrag mit Soultone Cymbals, der ihren Platz unter den Elite-Schlagzeugern festigte und ihren Sound auf das nächste Level hob.

Als ob das nicht genug wäre, erweiterte Victoria ihre musikalischen Horizonte, indem sie in der vierten Klasse das Violinspielen lernte. Am 13. Mai 2024 trat sie der World Youth Orchestra bei und hatte die Ehre, zusammen mit dem renommierten Duo Black Violin im Paramount Theatre aufzutreten, wo sie das Publikum von 2.000 Menschen mit ihrem außergewöhnlichen Talent verzauberte.

Ihr Weg endete jedoch nicht dort! Am 29. August 2024 trat sie der Ballard Civic Orchestra als Percussionistin bei und gab ihr Debüt im Town Hall Seattle während der Feier zum mexikanischen Unabhängigkeitstag vor fast 1.000 begeisterten Fans.

Victorias Auszeichnungen sind ebenso beeindruckend wie ihre Auftritte. Sie gewann den Goldpreis und die Auszeichnung für fantastische Technik beim London Young Musician Contest 2022 und wurde mit dem Annual Star Musician Award und dem Annual Outstanding Drummer Special Award bei den London Young Musician of the Year 2023 geehrt.

Nun fest in Seattle verwurzelt, übt Victoria täglich, um ihre Schlagzeug- und Violin-Fähigkeiten weiter zu verbessern. Mit den Augen auf eine aufregende Zukunft in der Musik gerichtet, hat diese dynamische junge Künstlerin erst angefangen. Mach dich bereit, dich von Victoria Acosta inspirieren zu lassen – wo Rhythmus auf Leidenschaft trifft und Träume wahr werden!
`,

	// PRESS PAGE

	t_footer: 'Alle Rechte vorbehalten',

		// SOULTONE: 

		b_visit_artist_page: 'Besuchen Sie meine Künstlerseite',

}

export default de;
