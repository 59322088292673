import React from 'react';

import drumIcon from '../assets/img/drum-icon.png'; // Ensure this path is correct
import { Nav, Image } from 'react-bootstrap';
import { IconType } from 'react-icons/lib';
import Localization from '../localization';
import { TbHandFinger } from 'react-icons/tb';

const strings = Localization.getStrings();

type DrumIconProps = {
	id: string,
	Icon: IconType,
	title: string,
	testID?: string,
	handler?: React.MouseEventHandler<HTMLElement>
}

const DrumIcon: React.FC<DrumIconProps> = (props) => {
	const { id, Icon, title, testID, handler } = props;

	const isTouchDevice = window.matchMedia('(hover: none)').matches;

	return (
			<Nav.Link 
				className={ `image-link ${id}`} 
				data-testid={ testID || `drum-icon-${id}`} 
				onClick={ handler }
			>
				<div className="image-container">
					<Image src={ drumIcon } alt={ title } className='drum-icon image' />
					<Icon className='drum-icon-logo' />
				</div>
				<small className='image-label'>
					{`${isTouchDevice ? strings.b_tap : strings.b_click} `}
					<TbHandFinger size={ 22 } className='hand-icon'/>
				</small>
			</Nav.Link>
	);
}

export default DrumIcon;
